<template>
  <div>
    <common-header ref="header"></common-header>
    <div :class="isWeiXin() ? 'home-fixed-weixin' : 'home-fixed'">
      <div class="content-fixed">
        <div class="shopping-home warp" ref="searchbox">
          <!-- 搜索 start -->
          <div class="search-box" @click="$router.push({ path: '/shopping/search?isLoad=1' })">
            <input class="search-input" type="text" placeholder="搜索商品" readonly />
            <i class="icon-search-fdj"></i>
          </div>
          <!-- 搜索 end -->

          <!-- 一级分类 start -->
          <div class="class-a">
            <swiper :options="classASwiper" ref="swiperA">
              <swiper-slide
                class="item"
                :class="tabAIndex == index ? 'active' : ''"
                v-for="(item, index) in classA"
                :key="index"
              >{{ item.name }}</swiper-slide>
            </swiper>
          </div>
          <!-- 一级分类 end -->
        </div>
        <div class="content" :style="`height:${centerHeight}px`">
          <!-- 二级分类 start -->
          <div class="class-b">
            <div
              class="item show-one"
              :class="tabBIndex == index ? 'active' : ''"
              v-for="(item, index) in classB"
              :key="index"
              @click="classClist(item.id, index, false, item.tagsId)"
            >{{ item.name }}</div>
          </div>
          <!-- 二级分类 end -->

          <div class="right-content">
            <div class="gd-banner" v-if="banners.length > 0">
              <swiper class="gd-swiper" :options="gdSwiper">
                <swiper-slide v-for="(item, index) in banners" :key="index">
                  <img class="w100" :src="requestImgUrl(item.img)" alt v-if="item.url == ''" />
                  <div v-else>
                    <a :href="item.url" v-if="isHttp(item.url)">
                      <img class="w100" :src="requestImgUrl(item.img)" alt />
                    </a>
                    <router-link :to="item.url" v-else>
                      <img class="w100" :src="requestImgUrl(item.img)" alt />
                    </router-link>
                  </div>
                </swiper-slide>
              </swiper>
            </div>

            <!-- 三级分类 start -->
            <div class="class-c pre" v-if="classC && classC.length > 0">
              <swiper :options="classCSwiper" ref="swiperC">
                <swiper-slide class="item-slide">
                  <div class="item" :class="tabCIndex == 0 ? 'active' : ''">全部</div>
                </swiper-slide>
                <swiper-slide class="item-slide" v-for="(item, index) in classC" :key="index">
                  <div class="item" :class="tabCIndex - 1 == index ? 'active' : ''">{{ item.name }}</div>
                </swiper-slide>
              </swiper>
              <a
                href="javascript:"
                :class="
                  threeIconActive ? 'class-c-icon active' : 'class-c-icon'
                "
                @click="threeIconClick"
              >
                <i class="arrow icon-hui-arrow-right"></i>
              </a>

              <div class="three-type" v-show="threeIconActive">切换品类</div>
              <div class="class-arrow-list" :style="threeIconActive ? 'height:auto' : 'height:0'">
                <div :class="`class-c-list ${threeIconActive ? 'active' : ''}`">
                  <span
                    class="item-c"
                    :class="tabCIndex == 0 ? 'active' : ''"
                    @click="threeTypeClick(0)"
                  >全部</span>
                  <span
                    class="item-c"
                    :class="tabCIndex - 1 == index ? 'active' : ''"
                    v-for="(item, index) in classC"
                    :key="index"
                    @click="threeTypeClick(index + 1)"
                  >{{ item.name }}</span>
                </div>
              </div>
            </div>
            <!-- 三级分类 end -->

            <!-- 商品列表 start -->
            <div
              class="goods-list"
              :style="
                threeIconActive ? 'overflow-y:hidden' : 'overflow-y:scroll'
              "
              @scroll="goodsScroll"
              ref="good_list"
            >
              <div class="pre">
                <div
                  :class="`goods-fixed ${threeIconActive ? 'active' : ''}`"
                  :style="
                    `display:${
                      threeIconActive ? 'block' : 'none'
                    };min-height:${goodListHeight}px`
                  "
                  @click="threeIconClick"
                ></div>

                <mt-loadmore
                  :top-method="loadTop"
                  :bottom-method="loadBottom"
                  ref="loadmore"
                  @top-status-change="handleTopChange"
                  @bottom-status-change="handleBottomChange"
                  :bottomDistance="100"
                  topPullText
                  topDropText
                  topLoadingText
                >
                  <div slot="top" class="mint-loadmore-top" v-if="tabBIndex > 0">
                    <span v-show="topStatus !== 'drop'">下拉 查看{{ classB[tabBIndex - 1].name }}</span>
                    <span v-show="topStatus === 'drop'">松开 查看{{ classB[tabBIndex - 1].name }}</span>
                  </div>
                  <div slot="bottom" class="mint-loadmore-bottom" v-if="classB.length > 0 && tabBIndex != classB.length -1">
                    <span v-show="bottomStatus !== 'drop'">上拉 查看{{ classB[tabBIndex + 1].name }}</span>
                    <span v-show="bottomStatus === 'drop'">松开 查看{{ classB[tabBIndex + 1].name }}</span>
                  </div>
                  <ul
                    class="goods-ul"
                    :style="`min-height:${goodListHeight}px`"
                    v-infinite-scroll="loadMore"
                    infinite-scroll-disabled="loading"
                    infinite-scroll-immediate-check="false"
                    ref="good_ul"
                    infinite-scroll-distance="10"
                  >
                    <li
                      class="goods-li"
                      v-for="(item, index) in commodityList"
                      :key="index"
                      @click="
                        $router.push({
                          path: '/shopping/details',
                          query: { id: item.id },
                        })
                      "
                    >
                      <div class="item">
                        <div class="good-imgbox pre">
                          <img
                            class="good-img"
                            v-lazy="`${requestImgUrl($transObjUndefined(JSON.parse(item.thumbnail)[0]).url)}?x-oss-process=image/resize,m_lfit,w_200`"
                            :key="`${requestImgUrl($transObjUndefined(JSON.parse(item.thumbnail)[0]).url)}?x-oss-process=image/resize,m_lfit,w_200`"
                            alt
                          />
                          <span
                            class="quehuo"
                            v-if="
                              item.specList &&
                                item.specList.length > 0 &&
                                item.specList[0].inventory === 0
                            "
                          >缺货</span>
                        </div>
                        <div class="right-goods-item">
                          <div class="good-name show-one">{{ item.name }}</div>
                          <div
                            class="ms show-one"
                            v-if="
                              !item.specList[0].seckill &&
                                item.specList[0].minLimit == 1 &&
                                !item.tags
                            "
                          >{{ item.description }}</div>
                          <div class="tags-box" v-else>
                            <div class="tag m-kill">
                              月销{{
                              item.virtualMonthlySales + item.monthlySales
                              }}{{item.unitName != '' ? item.unitName : ''}}
                            </div>
                            <div class="tag m-kill" v-if="item.specList[0].seckill">秒杀</div>
                            <template v-if="item.tags">
                              <div
                                class="tag tags-item"
                                v-for="(tagItem, tagIndex) in item.tags.split(
                                  ','
                                )"
                                :key="tagIndex"
                              >{{ tagItem }}</div>
                            </template>
                            <div class="tag buy-from" v-if="item.specList[0].minLimit > 1">
                              {{ item.specList[0].minLimit
                              }}{{item.specList[0].unitName!=''?item.specList[0].unitName:(item.unitName)}}起购
                            </div>
                          </div>
                          <div class="price-details"  v-if="item.unitName != item.specList[0].minUnitName && item.specList[0].unitQuantity > 1 && !item.specsShow && item.specList[0].price > 0">
                             <span class="unit-tips" v-if="item.specList[0].unitName != item.specList[0].minUnitName && item.specList[0].unitQuantity > 1">约{{item.specList[0].unitQuantity}}{{item.specList[0].minUnitName}}</span>
                            <span :class="item.unitName != item.specList[0].minUnitName && item.specList[0].unitQuantity > 1 ? 'hui' : ''">
                              <span class="unit">￥</span>{{(Number(item.specList[0].price) + item.specList[0].deposit)}}<span class="unit">{{item.specList[0].unitName!=''? '/'+item.specList[0].unitName:(item.unitName != '' ? `/${item.unitName}` : '')}}</span>
                            </span>
                            <div class="deposit" v-if="item.specList[0].deposit > 0">(含押金{{item.specList[0].deposit}}元)</div>
                          </div>
                          <div class="bottom">
                            <div class="price-box">
                              <div class="is-certified" v-if="item.specList[0].unitPrice < 0 && (Number(item.specList[0].price)) < 0 ">{{isCertified()}}</div>
                              <div v-else>
                                <div class="present-price" v-if="!item.specsShow">
                                  <div class="unit">￥</div>
                                  <div class="money" v-if="item.unitName != item.specList[0].minUnitName && item.specList[0].unitQuantity > 1">
                                    {{
                                    item.specList[0].unitPrice
                                    }}<span
                                      class="unit"
                                    >
                                      {{
                                      item.specList[0].minUnitName != ""
                                      ? "/" + item.specList[0].minUnitName
                                      : ""
                                      }}
                                    </span>
                                  </div>
                                  <div class="moeny" v-else>
                                    {{
                                    Number(item.specList[0].price) +
                                    item.specList[0].deposit
                                    }}<span
                                      class="unit"
                                    >
                                      {{item.specList[0].unitName!=''? '/'+item.specList[0].unitName:(
                                      item.unitName != ""
                                      ? "/" + item.unitName
                                      : "")
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              class="specs-btn-box jb-r-l"
                              v-if="item.specList && item.specList.length > 1"
                              @click.stop="changeSpecs(index)"
                            >{{ item.specsShow ? "收起" : "规格" }}</div>
                            <div
                              class="icon-car-btn"
                              v-else
                              @click.stop="
                                SelectProducts(
                                  item.id,
                                  item.specList[0].id,
                                  item.inventory
                                )
                              "
                            ></div>
                          </div>
                        </div>
                      </div>
                      <!-- 规格选项 start -->
                      <div
                        class="specs-box"
                        :class="item.specsShow ? 'active' : ''"
                        v-if="item.specList && item.specList.length > 1"
                      >
                        <div
                          class="specs-item"
                          v-for="(item2, index2) in item.specList"
                          :key="index2"
                        >
                          {{ item2.name }}
                          <span class="deposit" v-if="item2.deposit>0 && item.specList[0].unitPrice > 0">(含押金{{item2.deposit}}元)</span>
                          <span class="present-price" v-if="item2.price > 0">
                            <span class="unit">￥</span>
                            <span class="moeny">{{ Number(item2.price) + item2.deposit }}</span>
                            <span class="unit">{{item2.unitName!=''? '/'+item2.unitName:(item.unitName != "" ? "/"+item.unitName : "")}}</span>
                          </span>
                          <div class="is-certified" v-else>{{isCertified()}}</div>
                          <div
                            class="specs-btn jb-r-l"
                            @click.stop="SelectProducts(item.id, item2.id)"
                          >+</div>
                        </div>
                      </div>
                      <!-- 规格选项 end -->
                    </li>
                    <no-data v-if="nodata"></no-data>
                  </ul>
                </mt-loadmore>
              </div>
            </div>
            <!-- 商品列表 end -->
          </div>
        </div>
      </div>

      <!-- 未登录的时候显示 start -->
      <router-link to="/account/home" class="no-login" v-if="commodityList.length>0 && commodityList[0].unitPrice<0" >登录认证后显示价格</router-link>
      <!-- 未登录的时候显示 end -->
      <tabBar ref="tabbar"></tabBar>
    </div>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { requestImgUrl, isWeiXin, funcUrlDel } from "@/utils/common";
import { isHttp, isCertified} from "@/utils/utils";
import { setTimeout } from "timers";
export default {
  data() {
    this.isHttp = v => isHttp(v);
    this.requestImgUrl = (url, privates) => requestImgUrl(url, privates);
    this.isWeiXin = () => isWeiXin();
    this.isCertified = () => isCertified();
    return {
      topStatus: "",
      bottomStatus: "",
      banners: [],
      //暂无数据
      nodata: false,
      // 数据加载
      loading: false,
      tagsCode: "",
      classASwiper: {
        slidesPerView: "auto",
        spaceBetween: 15,
        on: {
          tap: e => {
            this.threeIconActive = false;

            this.classB = [];
            this.classC = [];
            this.commodityList = [];
            let swiperA = this.$refs.swiperA.swiper;
            let index = swiperA.clickedIndex;
            if (index >= 0) {
              const id = this.classA[index].id;
              this.tagsCode = this.classA[index].tagsCode
                ? this.classA[index].tagsCode
                : "";
              this.tabAIndex = index;
              this.classBlist(id);
            }
          }
        }
      },
      classCSwiper: {
        slidesPerView: "auto",
        spaceBetween: 7.5,
        on: {
          tap: () => {
            this.threeIconActive = false;

            this.loading = false;
            this.commodityList = [];
            let swiperC = this.$refs.swiperC.swiper;
            let index = swiperC.clickedIndex;
            this.tabCIndex = index;
            if (index > 0) {
              const id = this.classC[index - 1].id;
              this.getGoodsList(id);
            } else {
              let item = this.classB[this.tabBIndex];
              this.getGoodsList(item.id, 2);
            }
          }
        }
      },
      gdSwiper: {
        loop: true,
        autoplay: true
      },
      classA: [],
      classB: [],
      classC: [],
      tabAIndex: 0,
      tabBIndex: 0,
      tabCIndex: 0,
      // 商品列表
      commodityList: [],
      // 中间内容区域
      centerHeight: 0,
      uid: localStorage.getItem("uid"),
      pages: 1,
      pagenum: 1,

      scNum: 0,
      // 二级列表查询时，新增字段
      typeTagsId: "",
      startClientY: 0,
      pyNum: 0,
      // 是否可以上拉切换下一个分类
      isNextType: false,
      nextTypeTxt: "上拉",

      // 三级分类右侧图标点击
      threeIconActive: false,

      goodListHeight: 400,
      levelCode: {
        firstCode: '',
        secondCode: '',
        thirdCode: '',
        categoryCode: '',
      }
    };
  },
  activated() {
    let levelCode = this.levelCode;
    let { firstCode, secondCode, thirdCode, refresh } = this.$route.query;
    if (levelCode.firstCode != firstCode && firstCode) {
      this.typeTagsId = '';
      this.tagsCode = '';
      this.classB = [];
      this.classC = [];
      this.commodityList = [];
      levelCode.firstCode = firstCode;
      this.levelCode = levelCode;
      this.getList({
        level: 1,
        code: {
          firstCode: firstCode,
          secondCode: secondCode,
          thirdCode: thirdCode
        }
      }); 
    }
     

    setTimeout(() => {
      this.$refs.good_list.scrollTop = sessionStorage.getItem("ulTop");
    }, 10);
  },
  deactivated() {
    // console.log(this.levelCode.firstCode)
    // console.log('离开');
  },
  mounted() {
    // 计算可视区域
    

    // 广告
    this.getAd({
      positionCode: "M002"
    }).then(res => {
      this.banners = res.data;
    });
    // 获取数据
    let { firstCode, secondCode, thirdCode } = this.$route.query;
    this.levelCode = {
      firstCode: firstCode,
      secondCode: secondCode,
      thirdCode: thirdCode,
    }
    this.getList({
      level: 1,
      code: {
        firstCode: firstCode,
        secondCode: secondCode,
        thirdCode: thirdCode,
      }
    });
    setTimeout(() => {
      this.jsHeight();
    }, 800);    
  },
  methods: {
    // 显示加载提示
    showLoadTxt(type) {
      if (type == 'show') {
        this.$Indicator.open({
          text: "加载中...",
          spinnerType: "snake"
        });
      } else {
        this.$Indicator.close();
      }
    },

    // 监听滚动距离
    goodsScroll() {
      let scrollTop = this.$refs.good_list.scrollTop;
      sessionStorage.setItem("ulTop", scrollTop);
    },
    handleTopChange(status) {
      this.topStatus = status;
    },
    handleBottomChange(status) {
      this.bottomStatus = status;
    },
    loadBottom() {
      this.topStatus = "";
      if (this.tabBIndex != this.classB.length - 1) {
        this.pageNum = 1;
        this.classC = [];
        this.commodityList = [];
        let scIndex = this.tabBIndex + 1;
        let scItem = this.classB[scIndex];
        this.classClist(scItem.id, scIndex, false, scItem.tagsId);
      }
      this.$refs.loadmore.onBottomLoaded();
    },
    loadTop() {
      this.topStatus = "";
      if (this.tabBIndex > 0) {
        this.pageNum = 1;
        this.classC = [];
        this.commodityList = [];
        let scIndex = this.tabBIndex - 1;
        let scItem = this.classB[scIndex];
        this.classClist(scItem.id, scIndex, false, scItem.tagsId);
      }
      this.$refs.loadmore.onTopLoaded();
    },
    // 三级分类下拉点击事件
    threeTypeClick(index) {
      if (index === this.tabCIndex) {
        return;
      }
      this.threeIconActive = false;
      this.loading = false;
      this.commodityList = [];
      this.tabCIndex = index;
      if (index > 0) {
        const id = this.classC[index - 1].id;
        this.getGoodsList(id);
      } else {
        let item = this.classB[this.tabBIndex];
        this.getGoodsList(item.id, 2);
      }
    },
    // 三级分类右侧图标点击效果
    threeIconClick() {
      this.threeIconActive = !this.threeIconActive;
    },
    touchstart(e) {
      let clientY = e.changedTouches[0].clientY;
      this.startClientY = clientY;
      //判断元素是否出现了滚动条
      let good_list = this.$refs.good_list;
      if (
        good_list.scrollHeight <= good_list.clientHeight &&
        this.tabBIndex < this.classB.length - 1
      ) {
        //设置滚动条到最底部
        this.isNextType = true;
      }
    },
    touchmove(e) {
      let clientY = e.changedTouches[0].clientY;
      if (this.startClientY > clientY && this.isNextType) {
        let num = (this.startClientY - clientY) / 2;
        if (num > 40) {
          num = 40;
          this.nextTypeTxt = "松开";
        }
        this.pyNum = -num;
      } else {
        this.nextTypeTxt = "上拉";
        this.pyNum = 0;
      }
    },
    touchend(e) {
      if (this.isNextType && this.pyNum == -40) {
        this.isNextType = false;
        this.pageNum = 1;
        this.classC = [];
        this.commodityList = [];
        this.nextTypeTxt = "上拉";
        let scIndex = this.tabBIndex + 1;
        let scItem = this.classB[scIndex];
        this.pyNum = 0;
        this.classClist(scItem.id, scIndex, false, scItem.tagsId);
      } else {
        this.pyNum = 0;
      }
    },

    // 加载更多
    loadMore() {
      this.loading = true;
      let index = this.tabCIndex;
      let item =
        index == 0
          ? this.classB[this.tabBIndex]
          : this.classC[this.tabCIndex - 1];
      let pageNum = this.pageNum + 1;
      let pages = this.pages;
      if (pageNum > pages) {
        this.isNextType = true;
      } else {
        this.getGoodsList(item.id, index == 0 ? 2 : "", "add", pageNum);
      }
    },
    // 选择商品事件
    SelectProducts(commodityId, specId, inventory) {
      if (inventory <= 0) {
        this.$toast("卖光啦！");
        return false;
      }
      this.AddCommodityCart({
        commodityId: commodityId,
        specId: specId,
        uid: this.uid
      }).then(res => {
        if (res.status == 200) {
          this.$toast("加入购物车成功");
          // 获取购物车总数
          this.getCommodityCount({
            uid: this.uid
          }).then(res => {
            this.goodsNumMuta(res.data.quantity);
          });
        }
      });
    },
    // 显示规格列表
    changeSpecs(index) {
      let commodityList = this.commodityList;
      commodityList[index].specsShow = !commodityList[index].specsShow;
      this.commodityList = commodityList;
    },
    // 获取一级二级三级列表分类
    getList(obj) {
      this.CommodityClass({
        parentId: obj.parentId,
        level: obj.level
      }).then(res => {
        this.classA = res.data;
        let id = res.data[0].id;
        let tagsId = res.data[0].tagsId ? res.data[0].tagsId : "";
        // 如果传了code 默认选中
        if (obj.code) {
          res.data.forEach((e, index) => {
            if (e.categoryCode == obj.code.firstCode) {
              this.tabAIndex = index;
              id = e.id;
              tagsId = e.tagsId ? e.tagsId : "";
            }
          });
        }
        this.classBlist(id, obj.code.secondCode, obj.code.thirdCode, tagsId);
      });
    },
    // 二级分类请求
    classBlist(id, secondCode, thirdCode, tagsId) {
      this.CommodityClass({
        parentId: id,
        level: 2,
        tagsId: tagsId,
        tagsCode: this.tagsCode
      }).then(res => {
        // 2级code
        if (res.data.length > 0) {
          let id = res.data[0].id;
          let tagsId = res.data[0].tagsId ? res.data[0].tagsId : "";
          if (secondCode) {
            res.data.forEach((e, index) => {
              if (e.categoryCode == secondCode) {
                this.tabBIndex = index;
                id = e.id;
                tagsId = e.tagsId;
              }
            });
          } else {
            this.tabBIndex = 0;
          }
          this.nodata = false;
          this.classB = res.data;
          this.classClist(id, this.tabBIndex, thirdCode, tagsId);

          // 新增
          this.typeTagsId = tagsId;
        } else {
          this.nodata = true;
          this.classB = [];
          this.classC = [];
          this.commodityList = [];
        }
      });
    },
    // 第三级查询列表
    classClist(id, index, thirdCode, tagsId) {
      this.showLoadTxt('show');
      this.classC = [];
      this.commodityList = [];
      this.threeIconActive = false;

      // 恢复上拉加载状态
      let _id = id;
      if (index || index == 0) {
        this.tabBIndex = index;
      }
      this.CommodityClass({
        parentId: _id,
        level: 3,
        tagsId: tagsId,
        tagsCode: this.tagsCode
      }).then(res => {
        if (thirdCode) {
          res.data.forEach((e, index) => {
            if (e.categoryCode == thirdCode) {
              this.tabCIndex = index + 1;
              _id = e.id;
            }
          });
        } else {
          this.tabCIndex = 0;
        }
        if (res.data.length > 0) {
          this.nodata = false;
          this.classC = res.data;
          if (thirdCode) {
            this.getGoodsList(_id);
          } else {
            this.getGoodsList(_id, 2);
          }
        } else {
          this.nodata = true;
          this.classC = [];
          this.commodityList = [];
        }
      });
    },
    // 获取商品列表
    getGoodsList(id, level, add, pageNum) {
      // level默认第三级，
      let obj = {};
      if (level) {
        obj = {
          pageNum: pageNum ? pageNum : 1,
          categorySecond: id,
          typeTagsId: this.typeTagsId,
          tagsCode: this.tagsCode
        };
      } else {
        obj = {
          pageNum: pageNum ? pageNum : 1,
          categoryThird: id,
          tagsCode: this.tagsCode
        };
      }
      
      this.GetCommodityList(obj).then(msg => {
        let arrs = [];
        msg.data.list.forEach(element => {
          element.specsShow = false;
          arrs.push(element);
        });
        if (add) {
          this.commodityList = this.commodityList.concat(arrs);
        } else {
          this.commodityList = arrs;
          this.goodListHeight = this.$refs.good_list.clientHeight;
        }
        this.pageNum = msg.data.pageNum;
        this.pages = msg.data.pages;
        this.nodata = arrs.length > 0 ? false : true;
        this.loading = false;
        this.showLoadTxt('hide');
      });
    },

    jsHeight() {
      let innerHeight = window.innerHeight;
      const headerHeight = isWeiXin() ? 0 : 40;
      const tabberHeight = this.$refs.tabbar.$el.offsetHeight;
      const searchHeight = this.$refs.searchbox.offsetHeight;
      let height = innerHeight - headerHeight - tabberHeight - searchHeight;
      this.centerHeight = height;
      console.log("已计算出高度")
  
    },

    ...mapActions(["getAd"]),
    ...mapActions("shopping", [
      "GetCommodityList",
      "CommodityClass",
      "getCommodityCount",
      "AddCommodityCart"
    ]),
    ...mapMutations(["goodsNumMuta"])
  },
  computed: {
    ...mapState(["goodsNum"])
  }
};
</script>
<style lang="less" scoped>
.no-login{
  position: fixed;
  bottom: 1.13rem;
  left: 0;
  width: 100%;
  background: #ccc;
  text-align: center;
  color: #ff9662;
  font-size: .24rem;
  padding: .1rem;
  background: #ffe3cb;
  z-index: 999;
}
.money{
  display: flex;
  align-items: flex-end;
}
.unit-tips{
  font-size: .20rem;
  background: #ffefea;
  padding: .03rem .05rem;
  border-radius: .05rem;
  margin-right: .05rem;
  color: #ff7241;
}
.price-details{
  display: flex;
  margin-top: .15rem;
  margin-bottom: -0.15rem;
  align-items: center;
  .active{
    color: #ff7241;
  }
  .hui{
    color: #8a8a8a;
    font-weight: normal;
    font-size: .25rem;
  }
  .no-unitName{
    margin-top: .15rem;
    display: flex;
    align-items: center;
  }
  .unit{
    font-size: 0.2rem;
  }
}
.deposit{
  color: #ccc;
  font-size: .24rem;
  margin-left: .1rem;
}
.home-fixed {
  position: fixed;
  top: 40px;
  left: 0;
  width: 100%;
  height: 100%;
}

.home-fixed-weixin {
  position: fixed;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
}

.change-type {
  text-align: center;
  font-size: 0.26rem;
  padding-top: 0.2rem;
  padding-bottom: 1.2rem;
  color: #ccc;
}
.tags-box {
  white-space: nowrap;
  max-width: 3rem;
  overflow: hidden;
  overflow-x: scroll;
}
.gd-banner {
  margin-top: 0.2rem;
  padding: 0 0.2rem;
}
.specs-box {
  max-height: 0;
  overflow: hidden;
  &.active {
    max-height: 5rem;
  }
}
.specs-btn-box {
  color: #fff;
  font-size: 0.24rem;
  padding: 0.1rem;
  border-radius: 0.1rem;
}
.specs-item {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 0.28rem;
  height: 0.8rem;
  padding: 0 0.25rem;
  .is-certified{
    margin-top: 0rem;
    margin-left: .05rem;
    font-size: .24rem;
  }

  &:last-child {
    border-bottom: 1px solid #f7f7f7;
  }
  .present-price {
    margin-left: 0.1rem;
  }
  .specs-btn {
    height: 0.48rem;
    width: 0.48rem;
    border-radius: 50%;
    text-align: center;
    line-height: 0.48rem;
    color: #fff;
    margin-left: 0.2rem;
  }
}
.price-box {
  display: flex;
  align-items: center;
  justify-content: center;
  .price{
    display: flex;
    color: #ff7241;
    font-size: 0.32rem;
    font-weight: bold;
    flex-direction:column;
    
  }
}
.org-price {
  font-size: 0.24rem;
  color: #bfbfbf;
  text-decoration: line-through;
  line-height: 1;
  margin-left: 0.1rem;
}
.present-price {
  display: flex;
  align-items: flex-end;
  color: #ff7241;
  .unit {
    font-size: 0.24rem;
    // line-height: 1.5;
  }
  .moeny {
    font-size: 0.32rem;
    display: flex;
    align-items: flex-end;
  }
}
.goods-list {
  background: #fff;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  .goods-ul {
    padding: 0 0.3rem;
    .goods-li {
      background: #fff;
      margin-bottom: 0.2rem;
      .item {
        position: relative;
        display: flex;
        border-radius: 0.1rem;
        overflow: hidden;
        padding-bottom: 0.25rem;
        .right-goods-item {
          padding: 0 0 0 0.25rem;
          flex: 1;
          .ms {
            font-size: 0.24rem;
            color: #bfbfbf;
            margin-top: 0.1rem;
          }
        }
        .good-img {
          width: 1.6rem;
          height: 1.6rem;
          border-radius: 0.1rem;
        }
      }

      .quehuo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        z-index: 5;
        background-color: rgba(167, 167, 167, 0.7);
        color: #fff;
        width: 1rem;
        height: 1rem;
        font-size: 0.28rem;
        text-align: center;
        line-height: 1rem;
        display: block;
        border-radius: 1rem;
      }
      .good-name {
        font-size: 0.26rem;
        color: #212121;
      }
      .bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 0.2rem;
      }
    }
  }
  .title {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0.2rem 0;
    color: #000;
    .all-goods {
      font-size: 0.28rem;
      color: #727272;
      font-weight: normal;
    }
  }
}
.class-c {
  padding: 0.2rem;
  height: 0.85rem;
  background: #fff;

  .swiper-container {
    padding-right: 0.6rem;
  }

  .three-type {
    font-size: 0.28rem;
    color: #727272;
    position: absolute;
    top: 0.2rem;
    height: 0.45rem;
    background-color: #fff;
    left: 0;
    z-index: 99;
    width: 100%;
    padding: 0 0.2rem;
  }

  .class-c-icon {
    position: absolute;
    background-color: #fff;
    top: 0;
    right: 0;
    height: 100%;
    width: 0.85rem;
    z-index: 100;

    &.active {
      .arrow {
        transform: translateX(-50%) translateY(-50%) rotate(-90deg);
      }
    }

    .arrow {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%) rotate(90deg);
      z-index: 101;
      transition: all 0.3s;
    }
  }

  .class-arrow-list {
    position: absolute;
    left: 0;
    top: 0.85rem;
    z-index: 100;
    width: 100%;
    height: 0;
    overflow: hidden;
  }

  .class-c-list {
    transform: translateY(-100%);
    background-color: #fff;
    transition: all 0.3s;
    padding: 0 0.2rem;
    transition-delay: 0.1;
    opacity: 0;
    max-height: 6.4rem;
    overflow-y: scroll;

    &.active {
      transform: translateY(0);
      opacity: 1;
    }

    .item-c {
      display: inline-block;
      font-size: 0.26rem;
      color: #727272;
      height: 0.45rem;
      padding: 0 0.2rem;
      line-height: 0.45rem;
      background: #fff;
      margin-bottom: 0.2rem;
      &.active {
        background: #ffefea;
        color: #ff7241;
      }
    }
  }

  .item-slide {
    width: auto;
  }
  .item {
    display: inline-block;
    font-size: 0.26rem;
    color: #727272;
    height: 0.45rem;
    padding: 0 0.2rem;
    line-height: 0.45rem;
    background: #fff;
    &.active {
      background: #ffefea;
      color: #ff7241;
    }
  }
}
.gd-swiper {
  height: 1.3rem;
}
.right-content {
  padding-left: 1.9rem;
  display: flex;
  flex-direction: column;
  // padding-right: 0.2rem;
  box-sizing: border-box;
  background: #fff;
  height: 100%;
  .goods-list {
    background: #fff;
    flex: 1;
    position: relative;
    z-index: 1;
  }

  .goods-fixed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 10;
    opacity: 0;
    transition: all 0.3s;
    transition-delay: 0.1;

    &.active {
      opacity: 1;
    }
  }
}

.content-fixed {
  // position: fixed;
  // top: 0;
  // left: 0;
  // padding-bottom: 1.2rem;
  width: 100%;
  height: 100%;
  // padding-top: 40px;
  display: flex;
  flex-direction: column;
  z-index: 99999;
}
.content {
  position: relative;
  .class-b {
    overflow-y: scroll;
    min-width: 1.9rem;
    max-width: 1.9rem;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: #f7f7f7;
    .item {
      height: 1rem;
      line-height: 1rem;
      width: 1.9rem;
      font-size: 0.28rem;
      color: #212121;
      position: relative;
      padding-left: 0.2rem;
      padding-right: 0.2rem;
      text-align: center;
      &.active {
        color: #ff7241;
        background: #fff;
        &::after {
          position: absolute;
          top: 50%;
          left: 0;
          width: 0.05rem;
          height: 0.44rem;
          background: #ff7241;
          content: "";
          margin-top: -0.22rem;
        }
      }
    }
  }
}
.class-a {
  margin-top: 0.3rem;
  .item {
    width: auto;
    font-size: 0.28rem;
    color: #727272;
    &.active {
      color: #ff7241;
      font-weight: bold;
    }
  }
}
.shopping-home {
  padding-top: 0.2rem;
  display: flex;
  flex-direction: column;
  padding-bottom: 0.2rem;
  height: 1.76rem;
}
.search-box {
  width: 100%;
  height: 0.68rem;
  background: #fff;
  border-radius: 0.3rem;
  position: relative;
  .icon-search-fdj {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    margin-left: -0.75rem;
  }
  .search-input {
    width: 100%;
    height: 100%;
    font-size: 0.26rem;
    text-align: center;
  }
}
</style>
